import { useRef, useContext } from "react";
import { DrawerContext } from "src/providers/DrawerProvider";
import NFTStack from "src/componets/NFTStack";
import { Button } from "react-bootstrap";

const CollectiveBlock = () => {
	const selectedNFT = useRef(null);

	const { openDrawer } = useContext(DrawerContext);

	const handleNFTSelection = selectedType => {
		openDrawer(selectedType);
		selectedNFT.current = selectedType;
	};

	const buyNow = e => {
		e.preventDefault();
		openDrawer("goldNFT");
	};

	return (
		<>
			<div id="collective-block">
				<div className="container-fluid gx-0">
					<div className="row gx-0 align-items-center">
						<div className="col-12 col-md-7">
							<div className="content">
								<div className="mobile-background-block">
									<h1>
										<span className="block">Join The Orientalist</span>{" "}
										<span className="block">Founders Collective</span>
									</h1>
									<h3>as we create one of the most innovative spirit houses in the world.</h3>
								</div>
								<div className="mobile-regular-block">
									<p>
										Members of the collective will be delighted with unique experiences across the
										globe; from acquiring limited edition Orientalist Spirits whiskies, to attending
										fashion weeks in the fashion capital of the world, exclusive tasting sessions
										with spirit connoisseurs, high-fashion collaborations with renowned brands and
										members-only socials gatherings to revel the different seasons.
									</p>
									<p>
										Each membership comes in the form of an NFT, with verified ownership on
										Blockchain. The Founders Collective membership will grant you eligibility to
										receive a 5-Bottle Set of 12YO Dragon Whisky (2023 blend), +1 Bottle of Limited
										Edition Founders Collective Whisky.
									</p>

									<div className="row align-items-center">
										<div className="col-12 col-md-5 the-nfts-text text-right">
											<Button variant="primary" size="lg" onClick={buyNow}>
												Purchase Now
											</Button>
										</div>
										<div className="col-12 col-md-7 the-nfts">
											<NFTStack onClick={handleNFTSelection} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-5 float-end"></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CollectiveBlock;
