const AwardBlock = () => (
	<div id="awards-block">
		<div className="container-fluid gx-0">
			<div className="row gx-0 align-items-center">
				<div className="col-12 col-md-7">
					<div className="content">
						<div className="mobile-background-block">
							<div className="row gx-0 awards">
								<div className="col-2 col-md-2">
									<img src="images/award-a.png" alt="SIP Awards" />
								</div>
								<div className="col-2 col-md-2">
									<img src="images/award-b.png" alt="London Spirit Competition" />
								</div>
								<div className="col-2 col-md-2">
									<img src="images/award-c.png" alt="IWSC" />
								</div>
								<div className="col-2 col-md-2">
									<img src="images/award-d.png" alt="Vodka Masters Asia" />
								</div>
								<div className="col-2 col-md-2">
									<img src="images/award-e.png" alt="Singapore WSC" />
								</div>
								<div className="col-2 col-md-2">
									<img src="images/award-f.png" alt="San Franisco WSC" />
								</div>
							</div>
							<h1>
								<span className="block">
									<span className="thin">The Artisanal Story</span>
								</span>
								<span className="block">from The Orient</span>{" "}
							</h1>
							<h3>Discover the First Truly Pan Asian Craft Spirits</h3>
						</div>
						<div className="mobile-regular-block">
							<p>
								Founded by serial entrepreneur and restaurateur Michel Lu in mystical magical Shangri-La
								(3,300m above sea-level), The Orientalist Spirits is the world's first super premium
								pan-Asian craft spirits company.
							</p>
							<p>
								<strong>The core range includes</strong>
							</p>
							<p>
								<span className="highlighted">8YO Dragon Whisky,</span> an 8 year old double-wood
								matured blend with whiskies obtained from the top 3 whisky regions in Asia: Japan,
								Taiwan and India.
							</p>
							<p>
								<span className="highlighted">Origins Vodka,</span> a triple blended vodka made from
								potato vodka, longan honey vodka and Tibetan highland barley vodka.
							</p>
							<p>
								<span className="highlighted">Gunpowder Gin,</span> a contemporary gin that is produced
								using a blend of traditional and low temperature vacuum distillation methods and
								techniques.
							</p>
							<p>
								<span className="highlighted">Solera 23 Imperial Rum,</span> a small batch hand-crafted
								dark sipping rum made from a blend of rums between 6 and 23 years of age from Thailand,
								Philippines and Indonesia aged at origin in the classic Solera process from a series of
								ex-bourbon and Pedro Ximenez sherry barrels.
							</p>
							<p>
								To find out more please visit{" "}
								<a href="https://www.theorientalistspirits.com" target="_blank">
									https://www.theorientalistspirits.com
								</a>
								.
							</p>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-5">
					<div className="brand-video">
						<video controls width="100%">
							<source src="images/branding.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default AwardBlock;
