import { ethers } from "ethers";
import { web3provider } from "src/plugins/Web3Connection";
import { ContractCtrl, SignerCtrl } from "@web3modal/core";
import { toEtherValue, toWeiValue } from "src/helpers/numeric.helper";

//export const vestingContractAddress = process.env.REACT_APP_VESTINGERC721_ADDRESS;
export const PrivateNFTContractAddress = process.env.REACT_APP_PRIVATE_DROPERC721_ADDRESS;
export const PublicNFTContractAddress = process.env.REACT_APP_PUBLIC_DROPERC721_ADDRESS;

const compiledNFTContract = require("src/contracts/DropERC721.json");
//const compiledVestingContract = require("src/contracts/VestingERC721.json");

const executeContract = (nftType, methodName, payableValue = 0, ...params) =>
	new Promise(async (resolve, reject) => {
		const contractAddress = nftType === "goldNFT" ? PrivateNFTContractAddress : PublicNFTContractAddress;
		const compiledContract = compiledNFTContract; //contract === "nft" ? compiledNFTContract : compiledVestingContract;

		callOnContract(contractAddress, compiledContract.abi, methodName, payableValue, params)
			.then(response => {
				resolve(response);
			})
			.catch(error => reject(error));
	});

const callOnContract = (contractAddress, contractABI, methodName, payableValue, params) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		try {
			const signer = web3provider.getSigner();

			const transactionContract = new ethers.Contract(
				contractAddress,
				contractABI,
				payableValue ? signer : web3provider
			);

			const tx = await transactionContract[methodName](
				...params,
				payableValue ? { value: payableValue.toString() } : {}
			);
			if (payableValue) {
				const txRcpt = await tx.wait();
				if (txRcpt.status === 1) resolve(txRcpt);
				else reject("error");
			} else {
				resolve(tx);
			}
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});

export const getActiveClaimCondition = nftType =>
	new Promise(async (resolve, reject) => {
		let response = { pricePerToken: 0 };
		try {
			const activeClaimID = await executeContract(nftType, "getActiveClaimConditionId");

			if (activeClaimID) {
				// get the claim details
				const claimDetails = await executeContract(
					nftType,
					"getClaimConditionById",
					0,
					activeClaimID.toNumber()
				);

				if (claimDetails) {
					response = {
						conditionId: activeClaimID.toNumber(),
						startTime: claimDetails[0].toNumber(),
						maxClaimableSupply: claimDetails[1].toNumber(),
						supplyClaimed: claimDetails[2].toNumber(),
						quantityLimitPerWallet: claimDetails[3].toNumber(),
						merkleRoot:
							claimDetails[4] !== "0x0000000000000000000000000000000000000000000000000000000000000000"
								? claimDetails[4]
								: null,
						pricePerToken: toEtherValue(claimDetails[5]),
						currency: claimDetails[6],
					};
				}
			}
		} catch (e) {
			console.log(e);
		}

		resolve(response);
	});

export const getWalletsClaimBalance = (nftType, account) =>
	new Promise(async resolve => {
		let response = {};

		try {
			const allocationDetails = await executeContract(nftType, "walletAllocationCount", 0, account);
			const claimedDetails = await executeContract(nftType, "walletClaimCount", 0, account);

			if (allocationDetails?.toNumber() > 0) {
				response.allocatedQty = allocationDetails.toNumber();
			}

			if (claimedDetails?.toNumber() > 0) {
				response.claimedQty = claimedDetails.toNumber();
			}
		} catch (e) {
			console.log(e);
		}

		resolve(response);
	});

export const verifyClaimMerkleProof = (nftType, conditionId, claimer, proofs) =>
	new Promise(async (resolve, reject) => {
		try {
			console.log(conditionId, claimer, proofs);
			await executeContract(nftType, "verifyClaimMerkleProof", 0, conditionId, claimer, proofs);
			resolve(true);
		} catch (e) {
			reject(e);
		}
	});

export const claimNFT = (nftType, account, provider, quantity, currency, price, proofs) =>
	new Promise(async (resolve, reject) => {
		try {
			console.log(account, quantity, currency, price, proofs);
			if (provider === "walletconnect") {
				const signer = await SignerCtrl.fetch({ chainId: parseInt(process.env.REACT_APP_CHAIN_ID) });
				await ContractCtrl.write({
					address: nftType === "goldNFT" ? PrivateNFTContractAddress : PublicNFTContractAddress,
					abi: compiledNFTContract.abi,
					functionName: "claim",
					chainId: parseInt(process.env.REACT_APP_CHAIN_ID),
					args: [account, quantity, currency, toWeiValue(`${price}`), proofs],
					signer,
					overrides: {
						value: toWeiValue(`${price * quantity}`),
					},
				});
			} else {
				const tx = await executeContract(
					nftType,
					"claim",
					toWeiValue(`${price * quantity}`),
					account,
					quantity,
					currency,
					toWeiValue(`${price}`),
					proofs
				);
			}
			resolve(true);
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});

export const transferToken = (account, sendToWallet, tokenID) =>
	new Promise(async (resolve, reject) => {
		try {
			const compiledContract = compiledNFTContract;

			const signer = web3provider.getSigner();

			const transactionContract = new ethers.Contract(PrivateNFTContractAddress, compiledContract.abi, signer);

			const tx = await transactionContract["safeTransferFrom(address,address,uint256)"](
				account,
				sendToWallet,
				tokenID
			);
			const txRcpt = await tx.wait();
			console.log("m here");
			resolve(txRcpt);
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});
