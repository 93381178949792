const NFTStack = ({ onClick }) => {
	const swapOrBuy = e => {
		let stack = document.querySelector(".stack");

		let card = document.querySelector(".card:last-child");
		let img = document.querySelector(".card:first-child video");
		if (e.target !== img) {
			if (onClick) onClick(e.currentTarget.id);
			return;
		}
		card.style.animation = "swap 700ms forwards";

		setTimeout(() => {
			card.style.animation = "";
			stack.prepend(card);
		}, 700);
	};

	return (
		<>
			<div className="stack">
				<div className="card" id="publicNFT" onClick={swapOrBuy}>
					<video autoPlay muted loop width="100%" height="100%">
						<source src="images/regular.mp4" type="video/mp4" />
					</video>
					<span>Founders Collective NFT</span>
				</div>
				<div className="card" id="goldNFT" onClick={swapOrBuy}>
					<video autoPlay muted loop width="100%" height="100%">
						<source src="images/gold.mp4" type="video/mp4" />
					</video>
					<span>Private Founders Collective NFT</span>
				</div>
			</div>
		</>
	);
};

export default NFTStack;
