import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default ({ show, onAccept, onDecline }) => {
	return (
		<Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">ARE YOU OF LEGAL AGE?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					By clicking YES, you confirm that you are the required age of your country to visit our website,
					accept our Terms & Conditions.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onAccept} variant="primary">
					Yes, I AM
				</Button>
				<Button onClick={onDecline} variant="secondary">
					No
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
