import { useContext } from "react";
import { Button } from "react-bootstrap";
import { DrawerContext } from "src/providers/DrawerProvider";
const HeaderBlock = () => {
	const { openDrawer } = useContext(DrawerContext);

	const buyNow = e => {
		e.preventDefault();
		openDrawer("goldNFT");
	};

	const scrollTo = sectionID => {
		window.customScrollTo(sectionID);
	};

	return (
		<>
			<div id="home-block">
				<div className="container-fluid gx-0">
					<div className="row gx-0 align-items-center">
						<div className="col-12 col-md-7">
							<div className="content">
								<h1>JOURNEY WITH THE ORIENTALIST FOUNDERS COLLECTIVE</h1>
								<h3>
									Be a part of globally curated adventures designed to intrigue euphoric experiences,
									exclusive to our members.
								</h3>
								<p className="mt-5">
									<Button variant="primary" size="lg" onClick={buyNow}>
										Purchase Now
									</Button>
								</p>
								<div className="bullet-points">
									<h3 style={{ fontWeight: 500, textAlign: "center" }} className="mb-4">
										Perks Exclusive to Members
									</h3>
									<div className="row gx-4">
										<div className="col-12 col-md-4" onClick={() => scrollTo("#awards")}>
											<div>
												Pre-release of the much anticipated 5-Bottle Set of 12YO Dragon Whisky
												(2023 blend), +1 Bottle of Limited Edition Founders Collective Whisky.
											</div>
										</div>
										<div className="col-12 col-md-4" onClick={() => scrollTo("#events")}>
											<div>
												Access to events & parties globally including Fashion Week soirées,
												Amber Lounge parties, members-only gatherings & other red carpet
												receptions.
											</div>
										</div>
										<div className="col-12 col-md-4" onClick={() => scrollTo("#collective")}>
											<div>Verified ownership of the membership via NFT on blockchain.</div>
										</div>
									</div>
								</div>
								<div className="social-icons">
									<a href="https://www.facebook.com/theorientalistspirits" target="_blank">
										<img src="images/icon-fb.png" />
									</a>
									<a href="https://www.instagram.com/orientalistspirits/" target="_blank">
										<img src="images/icon-ig.png" />
									</a>
									<a href="https://opensea.io/collection/founders-collective-pass" target="_blank">
										<img src="images/icon-opensea.png" />
									</a>
									<a href="https://twitter.com/orientalist_xyz" target="_blank">
										<img src="images/icon-twitter.png" />
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-5 float-end">
							<img src="images/bottle.webp" />
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid media-logos-container">
				<div className="media-logos">
					<div className="row gx-0 align-items-center">
						<div className="col-4 col-md-2">As seen on</div>
						<div className="col-8 col-md-10">
							<div style={{ overflow: "hidden" }}>
								<div className="row gx-2 align-items-center img-ticker" style={{ flexWrap: "nowrap" }}>
									<div className="col-auto col-md-2">
										<img src="images/logo-business-spirit.png" />
									</div>
									<div className="col-auto col-md-2">
										<img src="images/logo-about-time.png" />
									</div>
									<div className="col-auto col-md-2">
										<img src="images/logo-wsta.png" />
									</div>
									<div className="col-auto col-md-2">
										<img src="images/logo-spill.png" />
									</div>
									<div className="col-auto col-md-2">
										<img src="images/logo-robbreport.png" />
									</div>

									<div className="col-auto col-md-2 d-block d-md-none">
										<img src="images/logo-business-spirit.png" />
									</div>
									<div className="col-auto col-md-2 d-block d-md-none">
										<img src="images/logo-about-time.png" />
									</div>
									<div className="col-auto col-md-2 d-block d-md-none">
										<img src="images/logo-wsta.png" />
									</div>
									<div className="col-auto col-md-2 d-block d-md-none">
										<img src="images/logo-spill.png" />
									</div>
									<div className="col-auto col-md-2 d-block d-md-none">
										<img src="images/logo-robbreport.png" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HeaderBlock;
