export default () => (
	<div id="faq-block">
		<div>
			<div className="container">
				<h1>FAQ</h1>
				<div className="accordion accordion-flush" id="faqAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingThree">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseThree"
								aria-expanded="false"
								aria-controls="collapseThree"
							>
								When will this be launched?
							</button>
						</h2>
						<div
							id="collapseThree"
							className="accordion-collapse collapse"
							aria-labelledby="headingThree"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">Private NFTs will go on sale 16th Nov onwards</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingSix">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseSix"
								aria-expanded="false"
								aria-controls="collapseSix"
							>
								What perks will I receive from this NFT?
							</button>
						</h2>
						<div
							id="collapseSix"
							className="accordion-collapse collapse"
							aria-labelledby="headingSix"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								See above section about our{" "}
								<a href="#utility" className="page-scroll">
									NFT
								</a>
								.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingEleven">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseEleven"
								aria-expanded="false"
								aria-controls="collapseEleven"
							>
								How do I get the actual bottle?
							</button>
						</h2>
						<div
							id="collapseEleven"
							className="accordion-collapse collapse"
							aria-labelledby="headingEleven"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								If you own the Orientalist Founders Collective Pass, you will need to be in full
								compliance of your jurisdiction to receive the bottle. You will need to submit your
								mailing address so we can ship your bottle safely using priority and insured mail.
								However, this will be subject to your country's jurisdiction and laws on alcohol
								imports. Shipping and local taxes will be borne by the member.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingSeven">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseSeven"
								aria-expanded="false"
								aria-controls="collapseSeven"
							>
								What happens after I claim the bottle of 12 year old Dragon Whisky and open it?
							</button>
						</h2>
						<div
							id="collapseSeven"
							className="accordion-collapse collapse"
							aria-labelledby="headingSeven"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								<p>
									We hope you enjoyed an exquisite drink. However, once the bottle has been claimed,
									it will alter the Founders Collective Pass NFT. The Orientalist Founders Collective
									NFT will then become a "membership only" edition which still allows you or the next
									holder all the benefits* that the Founders Collective Pass has to offer, the only
									difference is that the bottle will be gone forever.
								</p>
								<p>
									* Membership perks and benefits are subject to change at the discretion of the
									company
								</p>
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingTwelve">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseTwelve"
								aria-expanded="false"
								aria-controls="collapseTwelve"
							>
								How do I access my Orientalist Founders Collective NFT?
							</button>
						</h2>
						<div
							id="collapseTwelve"
							className="accordion-collapse collapse"
							aria-labelledby="headingTwelve"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								You will be able to view your Orientalist Founders Collective NFT through your
								blockchain wallet i.e metamask, coinbase, ledger etc or via email on{" "}
								<a href="https://paper.xyz/wallet" target="_blank">
									https://paper.xyz/wallet
								</a>
								.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingFive">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseFive"
								aria-expanded="false"
								aria-controls="collapseFive"
							>
								What blockchain is this project on?
							</button>
						</h2>
						<div
							id="collapseFive"
							className="accordion-collapse collapse"
							aria-labelledby="headingFive"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">Ethereum</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingNine">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseNine"
								aria-expanded="false"
								aria-controls="collapseNine"
							>
								What token standard is The Orientalist Founders Collective contract?
							</button>
						</h2>
						<div
							id="collapseNine"
							className="accordion-collapse collapse"
							aria-labelledby="headingNine"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">ERC-721</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingEight">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseEight"
								aria-expanded="false"
								aria-controls="collapseEight"
							>
								Who's the team behind The Orientalist Founders Collective Pass?
							</button>
						</h2>
						<div
							id="collapseEight"
							className="accordion-collapse collapse"
							aria-labelledby="headingEight"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								This project is launched by The Orientalist Spirits, founded by Michel Lu.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
