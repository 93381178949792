import { useContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { DrawerContext } from "./providers/DrawerProvider";
import Drawer from "./componets/Drawer";
import routes from "src/routes";
import BuyBlock from "./componets/BuyBlock";
import "./App.css";
import AgeGateway from "./componets/AgeGateway";

function App() {
	let element = useRoutes(routes);

	const { open, drawerFor, closeDrawer } = useContext(DrawerContext);

	const handleClose = () => {
		closeDrawer();
	};

	const [selectedAge, setSelectedAge] = useState(false);
	const [ageGatewayOpen, setAgeGatewayOpen] = useState(false);

	useEffect(() => {
		if (!selectedAge) {
			showAgeGateway();
		}
	}, []);

	const showAgeGateway = () => setAgeGatewayOpen(true);

	const handleAgeAccepted = () => {
		setSelectedAge(true);
		setAgeGatewayOpen(false);
	};
	const handleAgeDeclined = () => {
		window.location.href = "https://opensea.io/";
	};

	return (
		<div className="App">
			{element}
			<Drawer show={open} onClose={handleClose}>
				<BuyBlock nftType={drawerFor} />
			</Drawer>
			<AgeGateway show={ageGatewayOpen} onAccept={handleAgeAccepted} onDecline={handleAgeDeclined} />
		</div>
	);
}

export default App;
