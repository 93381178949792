export default () => (
	<div id="events-block">
		<div className="row gx-0">
			<div className="col-12">
				
				<div className="d-none d-md-block">
					<img src="/images/gathering.jpg" alt="Event List" />
				</div>
				<div className="d-block d-md-none">
					<img src="/images/gathering-mobile.jpg" alt="Event List" />
				</div>
			</div>
		</div>
	</div>
);
