import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Web3Modal } from "@web3modal/react";
import App from "./App";
import DrawerProvider from "./providers/DrawerProvider";
import BlockchainProvider from "src/providers/BlockchainProvider";

const web3ModalConfig = {
	projectId: process.env.REACT_APP_WALLETCONNECT_PROJECTID,
	theme: "dark",
	accentColor: "default",
	ethereum: {
		appName: "web3Modal",
		autoConnect: true,
	},
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Router>
			<BlockchainProvider>
				<DrawerProvider>
					<App />
				</DrawerProvider>
				<Web3Modal config={web3ModalConfig} />
			</BlockchainProvider>
		</Router>
	</React.StrictMode>
);
