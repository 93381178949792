export default () => (
	<>
		<div className="header-video-container">
			<video width="100%" autoPlay muted loop>
				<source src="/images/tos_web_banner.mp4" type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<a className="page-scroll" href="#home">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="currentColor"
					className="bi bi-chevron-up"
					viewBox="0 0 16 16"
				>
					<path
						fillRule="evenodd"
						d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
					/>
				</svg>
			</a>
		</div>
	</>
);
