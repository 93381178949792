export default () => (
	<div id="partners-block">
		<div>
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<h1>Partners</h1>
					</div>
				</div>
				<div className="row gx-4 g-md-5 mt-5">
					<div className="col-6 col-md-3">
						<img src="images/partners/michel-n.png" alt="Michel" />
					</div>
					<div className="col-6 col-md-3">
						<img src="images/partners/enj-n.png" alt="Enj" />
					</div>
					<div className="col-6 col-md-3">
						<img src="images/partners/xva-n.png" alt="XVA Asia" />
					</div>
					<div className="col-6 col-md-3">
						<img src="images/partners/whitepony-n.png" alt="WhitePony" />
					</div>
				</div>
			</div>
		</div>
	</div>
);
