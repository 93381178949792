export default () => (
	<div id="contact-block">
		<div>
			<div className="container">
				<div className="row justify-content-between">
					<div className="col-12 col-md-5">
						<h1>INTERESTED TO LEARN MORE? OR KEEN TO COLLABORATE?</h1>
						<h1>GET IN TOUCH.</h1>
					</div>
					<div className="col-12 col-md-6">
						<div id="msgSubmit" className="mt-3 hidden"></div>
						<form id="contactForm">
							<div className="form-group mb-2">
								<input
									type="text"
									className="form-control"
									id="name"
									name="name"
									placeholder="Name"
									required
									data-error="Please enter your name"
									autoComplete="off"
								/>
								<div className="help-block with-errors"></div>
							</div>
							<div className="form-group mb-2">
								<input
									type="email"
									className="form-control"
									id="email"
									name="email"
									placeholder="Email"
									required
									autoComplete="off"
								/>
								<div className="help-block with-errors"></div>
							</div>
							<div className="form-group mb-2">
								<input
									type="tel"
									className="form-control"
									id="phone"
									name="phone"
									placeholder="Mobile (Please provide area code)"
									required
									data-error="Please enter valid Mobile Number"
									pattern="[+0-9]{8,}"
									autoComplete="off"
								/>
								<div className="help-block with-errors"></div>
							</div>
							<div className="d-grid mb-2">
								<button className="btn btn-primary" type="submit">
									Submit
								</button>
							</div>
							<small>I AGREE TO SHARE MY CONTACT DETAILS</small>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
);
